import React from "react"
import styles from "../../css/blog-card.module.css"
// import Image from "gatsby-image"
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import * as Survey from "survey-react";
// import {sv_window} from "survey-react/modern.css";
import "survey-react/survey.css";
// "";
import { navigate } from "gatsby"
// import {modernCss} from "survey-react";

const WellnessSurvey = (props) => {

    // Survey.StylesManager.applyTheme("modern");

    var surveyJSON = {
        pages: [
            {
                name: "page1",
                elements: [
                    {type: "rating", name: "Ability to Managing stress", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Anxiety", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Appetite?", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Depression", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "High energy level", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Fitness programs", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Medication Stabilization", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Mood swings", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Motivation level", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Overall quality of life", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Panic attacks", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Quality of family relationships", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Quality of personal relationships", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Quality of work life", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Sleep", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Sobriety", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                    {type: "rating", name: "Spiritual Connectedness", rateMax: 10,  description: "1 - WORST and 10 - BEST"},
                ],
                title: "OVERALL MENTAL HEALTH (Page 1 of 3)"
            },
            {
                name: "page2",
                elements: [
                    {type: "rating", name: "Health", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Scheduling appointments", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Finance", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Legal", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Other family relationships?", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Relationship with children", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Relationship with significant other", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"},
                    {type: "rating", name: "Work", rateMax: 10, description: "1 - stressful and 10 - No Stress (It's a breeze)"}
                ],
                title: "SOCIAL FUNCTIONING (Page 2 of 3)"
            },
            {
                name: "page3",
                elements: [
                    {type: "rating", name: "Art", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Exercise", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Have someone I confide in", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Hobbies", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Long Drives", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Meditation", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Music", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Nutrition", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Other Vitality", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Spiritual practice", rateMax: 10,description: "1 - never and 10 - always"},
                    {type: "rating", name: "Time with Animals", rateMax: 10,description: "1 - never and 10 - always"}
                ],
                title: "VITALITY  (Page 3 of 3)"
            },
            {
                name: "page3",
                elements: [
                    {
                        type: "text",
                        name: "question6",
                        title: "Age",
                        isRequired: true,
                        inputType: "number",
                        min: "1",
                        max: "100"
                    },
                    {
                        type: "dropdown",
                        name: "question5",
                        title: "Gender",
                        choices: [
                            {
                                value: "item1",
                                text: "Male"
                            },
                            {
                                value: "item2",
                                text: "Female"
                            },
                            {
                                value: "item3",
                                text: "Not applicable"
                            }
                        ]
                    }
                ],
                title: "Basic demographic",
                description: "No identification information is collected or stored"
            }
        ],
        showProgressBar: "both",
        goNextPageAutomatic: true
    }

    function sendDataToServer(survey) {
        console.log('Survey ', survey.data);
        navigate('/surveyresult', {
            state: {
               data: survey.data
            },
            replace: true
        });
        // survey.sendResult('ee2ec927-9761-4fdc-ad31-e0c9d9f7553e');
    }

    return (
        <article>
            {/*<div className={styles.imgContainer}>*/}
            {/*<Image fluid={image.fluid} className={styles.img} alt="single post" />*/}
            <div id="surveyContainer"></div>
            {/*<AniLink fade className={styles.link} to={`/blog/${slug}`}>*/}
            {/*    read more*/}
            {/*</AniLink>*/}
            {/*<h6 className={styles.date}>{published}</h6>*/}
            <Survey.Survey json={surveyJSON} onComplete={sendDataToServer} />

            {/*</div>*/}
            <div className={styles.footer}>
                {/*<h4>{title}</h4>*/}
            </div>
        </article>
    )
}

export default WellnessSurvey;