import React from "react"
import Title from "../Title"
// import { useStaticQuery, graphql } from "gatsby"
import styles from "../../css/blog.module.css"
import WellnessSurvey from "./WellnessSurvey";


const WellnessSurveyList = () => {

    return (
        <section >
            <Title title="Check your quality of " subtitle="Life" />
            <div >
                <WellnessSurvey/>
            </div>
        </section>
    )
}

export default WellnessSurveyList
