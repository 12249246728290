import React from 'react'
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from 'gatsby'
import SEO from "../components/SEO"
import WellnessSurveyList from "../components/Survey/WellnessSurveyList";

export const query = graphql`
query {
    blogBcg: file(relativePath: {eq: "blogBcg.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 2160) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;

export default function blog({ data }) {
    return (
        <Layout>
            <SEO title="Quality of Life" description="News and blogs on Substance abuse, Addiction Treatment and Banjo" />
            <StyledHero img={data.blogBcg.childImageSharp.fluid} />
            <WellnessSurveyList/>
        </Layout>
    )
}